import styles from "./HomePage1.module.css";
import publishersimg from "../../icons/hopepage/login select/illustration.svg";
import authorimg from "../../icons/hopepage/login select/login select/illustration.svg";
import logo from "../../icons/hopepage/login select/brand.svg";
const HomePage1 = () => {
  return (
    <div className={styles.container}>
      <div className={styles.internalContainer}>
        {/* <div className={styles.logoheader}> */}
        <div className={styles.logo}>
          <img src={logo} alt="logo" className={styles.logoimage} />
          {/* <dev className={styles.textlogo}>
                        Storywise.ai
                    </dev> */}
        </div>
        {/* </div> */}
        <div className={styles.body}>
          {/* <div className={styles.title}> */}
          <div className={styles.titletext}>What brings you here?</div>
          {/* </div> */}

          <div className={styles.Autor_publishers_container}>
            <a href="https://www.storywisenetwork.com" className={styles.Autor}>
              <div className={styles.Autor_image}>
                <img src={authorimg} alt="Autor" className={styles.Autor_img} />
              </div>
              <div
                className={`${styles.Autor_text} ${styles.Autor_text_default}`}
              >
                <div className={styles.Autor_text_title}>Author</div>
                <div className={styles.Autor_text_body}>
                  Put the best version of your manuscript in front of the most
                  relevant publishers and agents
                </div>
              </div>
            </a>

            <a
              href="https://www.storywisepublishers.com"
              className={styles.publishers}
            >
              <div className={styles.publishers_image}>
                <img
                  src={publishersimg}
                  alt="publishers"
                  className={styles.Autor_img}
                />
              </div>
              <div className={styles.publishers_text}>
                <div className={styles.publishers_text_title}>
                  Publisher / Agent
                </div>
                <div className={styles.publishers_text_body}>
                  Discover new talent. Analyze and recommend authors and
                  manuscripts that match your wishlist
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage1;
