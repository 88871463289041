

import './App.css';
import './global.css';

import HomePage1 from './components/home/HomePage1';

function App() {
  

  
  return (
    <div >
        <HomePage1/>
    </div>

  );
}

export default App;
